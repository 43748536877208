@font-face {
  font-family: "iconfont"; /* Project id 2557031 */
  src: url('iconfont.eot?t=1742955494971'); /* IE9 */
  src: url('iconfont.eot?t=1742955494971#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('iconfont.woff2?t=1742955494971') format('woff2'),
       url('iconfont.woff?t=1742955494971') format('woff'),
       url('iconfont.ttf?t=1742955494971') format('truetype'),
       url('iconfont.svg?t=1742955494971#iconfont') format('svg');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-wailian:before {
  content: "\e609";
}

.icon-dingwei:before {
  content: "\e60a";
}

.icon-search:before {
  content: "\e65f";
}

.icon-jiantouxia:before {
  content: "\e695";
}

.icon-jiantouzuo:before {
  content: "\e860";
}

.icon-jiantoushang:before {
  content: "\e861";
}

.icon-jiantouyou:before {
  content: "\e862";
}

.icon-jia:before {
  content: "\e61b";
}

.icon-triangle-xia:before {
  content: "\e62d";
}

.icon-linkedin:before {
  content: "\e600";
}

.icon-Facebook:before {
  content: "\e606";
}

.icon-yuyan:before {
  content: "\e85f";
}

.icon-e-mail:before {
  content: "\e623";
}

