@import "../../assets/styles/utils";

header{
    &.scroll-header{
        .header-zhanwei,.header{
            @include res(height,80px,60 / 80);
        }
        .menu-mob{
            @include res(padding-top,80px,60 / 80);
        }
    }
    &.index-header{
        .header-zhanwei{
            height: 0;
        }
        .header{
            background-color: transparent;
            color: #fff;
            .header-center{
                ul{
                    li{
                        .sub-nav{
                            a{
                                color: #fff;
                            }
                        }
                    }
                }
            }
            .header-right{
                a{
                    color: #fff;
                    &:hover{
                        color:#149ad8;
                    }
                    &.tel{
                        color: #fff;
                    }
                }
            }
            .hamburger>div, .hamburger>div:after, .hamburger>div:before, .hamburger>view, .hamburger>view:after, .hamburger>view:before{
                background-color: #fff;
            }
            .hamburger.active>div, 
            .hamburger.active>div:after, 
            .hamburger.active>div:before, 
            .hamburger.active>view, 
            .hamburger.active>view:after, 
            .hamburger.active>view:before{
                background-color: #252d67;
            }
        }
    }
    .header-zhanwei{
        transition: all $anime-duration;
        @include res(height,$header-height-base,$header-height-ratio);
    }
    .header{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1000;
        background-color: $color-main-light;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        transition: all $anime-duration;
        @include res(height,$header-height-base,$header-height-ratio);
        .header-left{
            position: absolute;
            top: 0;
            height: 100%;
            display: flex;
            align-items: center;
            @include res(left,60px, 10 / 60);
            a{
                display: block;
                height: 40%;
                img{
                    display: block;
                    width: auto;
                    height: 100%;
                    max-height: 44px;
                }
            }
        }
        .header-center{
            height: 100%;
            @include res(display,null,(sm:none));
            ul{
                height: 100%;
                display: flex;
                align-items: center;
                li{
                    font-family: 'Lato-Bold';
                    position: relative;
                    transition: all $anime-duration;
                    @include res(font-size,$text-size-md-base,$text-size-md-ratio);
                    @include res(margin-right,50px, 20 / 50);
                    &:hover{
                        color: #149ad8;
                        .sub-nav{
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                    &:last-child{
                        margin-right: 0;
                        font-size: 14px;
                    }
                    .sub-nav{
                        position: absolute;
                        top: 100%;
                        right: 0;
                        border-radius: 3px;
                        overflow: hidden;
                        transition: all .2s;
                        opacity: 0;
                        visibility: hidden;
                        transform-origin: top;
                        a{
                            display: block;
                            // background-color: $color-main;
                            // padding: 0 10px;
                            white-space: nowrap;
                            color:#000;
                            &:hover{
                                // background-color: $color-main-dark;
                                color: #149ad8;
                            }
                        }
                    }
                }
            }
        }
        .header-right{
            position: absolute;
            top: 0;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            @include res(display,null,(sm:none));
            @include res(right,60px, 10 / 60);
            a{
                transition: all $anime-duration;
                &:hover{
                    color: #043b79;
                }
                color: #149ad8;
                @include res(margin-left,20px, 10 / 20);
                .iconfont{
                    @include res(font-size,18px, 16 / 18);
                }
                &.tel{
                    color: #043b79;
                    @include res(display,null,(md:none));
                    @include res(font-size,$text-size-lg-base,$text-size-lg-ratio);
                    &:hover{
                        color: #149ad8;
                    }
                }
            }
        }
        .hamburger{
            position: absolute;
            top: 0;
            height: 100%;
            @include res(right,60px, 10 / 60);
            @include res(display,none,(sm:block));
        }
    }
    .menu-mob{
        position: fixed;
        bottom: 100%;
        left: 0;
        width: 100%;
        height: 100vh;
        overflow-x: hidden;
        overflow-y: auto;
        background-color: $color-main-dark;
        z-index: 999;
        box-sizing: border-box;
        transition: all 1s ease-in-out;
        color: #fff;
        transition: all $anime-duration;
        @include res(padding-left,150px, 20 / 150);
        @include res(padding-right,150px, 20 / 150);
        @include res(padding-bottom, 100px, 20 / 100);
        @include res(padding-top,$header-height-base,$header-height-ratio);
        &.show{
            transform: translateY(100%);
        }
        ul{
            @include res(padding-top, 100px, 20 / 100);
            li{
                border-bottom: 1px solid rgba(255,255,255,.2);
                &.active{
                    .menu-title{
                        .iconfont{
                            transform: rotate(90deg);
                        }
                    }
                }
                .menu-title{
                    font-size: 20px;
                    font-family: 'Lato-Light';
                    padding: 10px 0;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .iconfont{
                        transition: all $anime-duration;
                    }
                }
                .sub-menu{
                    padding-top: 10px;
                    display: none;
                    a{
                        display: block;
                        padding-left: 20px;
                        margin-bottom: 10px;
                        font-size: 16px;
                    }
                }
            }
        }
        .other{
            margin-top: 30px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .contact{
                display: flex;
                align-items: center;
                @include res(justify-content,flex-start,(xs:center));
                a{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    background-color: #fff;
                    color: $color-main-dark;
                    transition: all $anime-duration;
                    @include res(width,55px, 35 / 55);
                    @include res(height,55px, 35 / 55);
                    @include res(margin-right,25px, 10 / 25);
                    &:last-child{
                        margin-right: 0;
                    }
                    &:hover{
                        background-color: rgba(255,255,255,.1);
                        color: #fff;
                    }
                    .iconfont{
                        @include res(font-size,24px, 16 / 24);
                    }
                }
            }
            .language{
                background: #333a71;
                border-radius: 5px;
                position: relative;
                @include res(padding-left,15px, 10 / 15);
                @include res(padding-right,15px, 10 / 15);
                @include res(padding-top,5px, 5 / 5);
                @include res(padding-bottom,5px, 5 / 5);
                &:hover{
                    border-bottom-right-radius: 0px;
                    border-bottom-left-radius: 0px;
                    .sub-language{
                        opacity: 1;
                        visibility: visible;
                    }
                }
                .icon-yuyan{
                    @include res(font-size,22px, 16 / 22);
                }
                span{
                    font-family: 'Lato-Bold';
                    @include res(font-size,$text-size-md-base,$text-size-md-ratio);
                    @include res(margin-left,10px, 10 / 10);
                    @include res(margin-right,20px, 10 / 20);
                }
                .sub-language{
                    position: absolute;
                    top: 100%;
                    right: 0;
                    width: 100%;
                    background: #333a71;
                    text-align: center;
                    border-bottom-right-radius: 5px;
                    border-bottom-left-radius: 5px;
                    overflow: hidden;
                    opacity: 0;
                    visibility: hidden;
                    transition: all $anime-duration;
                    @include res(font-size,$text-size-md-base,$text-size-md-ratio);
                    a{
                        display: block;
                        transition: all $anime-duration;
                        @include res(padding-top,10px, 5 / 10);
                        @include res(padding-bottom,10px, 5 / 10);
                        &:hover{
                            background: rgba(255,255,255,.5);
                            color: $color-main-dark;
                        }
                    }
                }
            }
        }  
    }
}
